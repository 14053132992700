import AbstractCollection from './AbstractCollection'
import utilities from '../utilities'

class RecurringVendorAppointment extends AbstractCollection {

    /**
   * @param { {id: ID} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async getRecurringVendorAppointment(input, options) {
    return this._get(
       'getRecurringVendorAppointment',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {id: ID} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async getRecurringVendorAppointment(input, options) {
    return new this().getRecurringVendorAppointment(input, options)
  }

  /**
   * @param { {filter?: ModelRecurringVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  async listRecurringVendorAppointments(input, options) {
    return this._list(
       'listRecurringVendorAppointments',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {filter?: ModelRecurringVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  static async listRecurringVendorAppointments(input, options) {
    return new this().listRecurringVendorAppointments(input, options)
  }

  /**
   * @param { {filter?: ModelRecurringVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  async iterateRecurringVendorAppointments(input, options) {
    return this._iterate(
       'listRecurringVendorAppointments',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {filter?: ModelRecurringVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  static async iterateRecurringVendorAppointments(input, options) {
    return new this().iterateRecurringVendorAppointments(input, options)
  }

  /**
   * @param { {residentId?: ID, lastOccurredAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelRecurringVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  async listRecurringVendorAppointmentsByResident(input, options) {
    return this._list(
       'listRecurringVendorAppointmentsByResident',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {residentId?: ID, lastOccurredAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelRecurringVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  static async listRecurringVendorAppointmentsByResident(input, options) {
    return new this().listRecurringVendorAppointmentsByResident(input, options)
  }

  /**
   * @param { {residentId?: ID, lastOccurredAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelRecurringVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  async iterateRecurringVendorAppointmentsByResident(input, options) {
    return this._iterate(
       'listRecurringVendorAppointmentsByResident',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {residentId?: ID, lastOccurredAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelRecurringVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  static async iterateRecurringVendorAppointmentsByResident(input, options) {
    return new this().iterateRecurringVendorAppointmentsByResident(input, options)
  }

  /**
   * @param { {buildingId?: ID, lastOccurredAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelRecurringVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  async listRecurringVendorAppointmentsByBuilding(input, options) {
    return this._list(
       'listRecurringVendorAppointmentsByBuilding',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {buildingId?: ID, lastOccurredAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelRecurringVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  static async listRecurringVendorAppointmentsByBuilding(input, options) {
    return new this().listRecurringVendorAppointmentsByBuilding(input, options)
  }

  /**
   * @param { {buildingId?: ID, lastOccurredAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelRecurringVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  async iterateRecurringVendorAppointmentsByBuilding(input, options) {
    return this._iterate(
       'listRecurringVendorAppointmentsByBuilding',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {buildingId?: ID, lastOccurredAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelRecurringVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  static async iterateRecurringVendorAppointmentsByBuilding(input, options) {
    return new this().iterateRecurringVendorAppointmentsByBuilding(input, options)
  }

  /**
   * @param { {vendorServiceId?: ID, lastOccurredAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelRecurringVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  async listRecurringVendorAppointmentsByVendorService(input, options) {
    return this._list(
       'listRecurringVendorAppointmentsByVendorService',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {vendorServiceId?: ID, lastOccurredAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelRecurringVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  static async listRecurringVendorAppointmentsByVendorService(input, options) {
    return new this().listRecurringVendorAppointmentsByVendorService(input, options)
  }

  /**
   * @param { {vendorServiceId?: ID, lastOccurredAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelRecurringVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  async iterateRecurringVendorAppointmentsByVendorService(input, options) {
    return this._iterate(
       'listRecurringVendorAppointmentsByVendorService',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {vendorServiceId?: ID, lastOccurredAt?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelRecurringVendorAppointmentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  static async iterateRecurringVendorAppointmentsByVendorService(input, options) {
    return new this().iterateRecurringVendorAppointmentsByVendorService(input, options)
  }

  /**
   * @param { {input: CreateRecurringVendorAppointmentInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async createRecurringVendorAppointment(input, options) {
    return this._mutate(
       'createRecurringVendorAppointment',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: CreateRecurringVendorAppointmentInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async createRecurringVendorAppointment(input, options) {
    return new this().createRecurringVendorAppointment(input, options)
  }

  /**
   * @param { {input: UpdateRecurringVendorAppointmentInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async updateRecurringVendorAppointment(input, options) {
    return this._mutate(
       'updateRecurringVendorAppointment',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: UpdateRecurringVendorAppointmentInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async updateRecurringVendorAppointment(input, options) {
    return new this().updateRecurringVendorAppointment(input, options)
  }

  /**
   * @param { {input: DeleteRecurringVendorAppointmentInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async deleteRecurringVendorAppointment(input, options) {
    return this._mutate(
       'deleteRecurringVendorAppointment',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: DeleteRecurringVendorAppointmentInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async deleteRecurringVendorAppointment(input, options) {
    return new this().deleteRecurringVendorAppointment(input, options)
  }


  // STATIC --------------------------------------------------------------
  static modelName = "RecurringVendorAppointment"

  /**
   * @param {*} obj
   * @returns {*}
   */
  static afterFind(obj) {
    // if we couldn't find any data, just return early -- nothing to format
    if (!obj) {
      return obj
    }

    Object.entries(RecurringVendorAppointment._complexFields).forEach(([field, fieldType]) => {
      // if this connected field is included in our response object
      if (Object.hasOwnProperty.call(obj, field)) {
        // we determine the correct model this field is connected to
        const modelName = utilities.getModelFromFieldType(fieldType)

        // load that models collection
        const associatedCollection = RecurringVendorAppointment.modelNameToCollectionMap[modelName]

        if (associatedCollection) {
          // and invoke that collection's afterFind method.
          // this allows any special query hook behavior for the associated collections to be applied to this payload as well
          obj[field] = Array.isArray(obj[field]) ? obj[field].map(v => associatedCollection.afterFind(v)) : associatedCollection.afterFind(obj[field])
        }
      }
    })


    // lastly, we apply our own custom query hooks to this payload if one is specified
    if (typeof RecurringVendorAppointment.custom_afterFind === 'function') {
      return RecurringVendorAppointment.custom_afterFind(obj)
    }

    return obj
  }

  /**
   * @override
   * @param {string?} fragmentName
   * @returns { RecurringVendorAppointment }
   */
  static as(fragmentName) {
    return new this(fragmentName)
  }
}

// this meta prop is used to call query hooks on associated models
RecurringVendorAppointment._complexFields = {"vendorService":"VendorService","paymentMethod":"PaymentMethod","addOns":"[VendorAppointmentAddOn]","metaPetProfileIds":"[ID]"}
RecurringVendorAppointment._jsonFields = []

RecurringVendorAppointment.FragmentDefault = `
fragment RecurringVendorAppointmentFragmentDefault on RecurringVendorAppointment {
  id
  vendorServiceId
  buildingId
  unitId
  residentId
  paymentMethodId
  frequency
  notes
  firstOccurredAt
  lastOccurredAt
  isDeleted
  createdAt
  updatedAt
}

`


export default RecurringVendorAppointment
