import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import * as Spacing from '../assets/styles/spacing'
import {
  faClock,
  faBellConcierge,
  faDogLeashed,
  faScrewdriverWrench,
  faSparkles,
  faBoxOpen,
  faDumbbell,
  faBuilding,
  faCommentDots,
  faCommentSlash,
  faCircleUser,
  faCalendarStar,
  faCalendarDays,
  faEllipsisVertical,
  faSpa,
  faPersonRunning,
  faNotebook,
  faMegaphone,
  faKey,
  faHatWizard,
  faArrowRightFromBracket,
  faBars,
  faEnvelopeOpenText,
  faCircleQuarters,
  faTrashCan,
  faCakeCandles,
  faChampagneGlasses,
  faPenClip,
  faHouseUser,
  faUserPen,
  faDollarSign,
  faCircleInfo,
  faInfo,
  faPaperPlaneTop,
  faSort,
  faSquareList,
  faMagnifyingGlass,
  faSliders,
  faPaw,
  faChartArea,
  faArrowUp,
  faArrowDown,
  faSmileBeam,
  faStar,
  faEllipsis,
  faArrowTurnDownRight,
  faBell,
  faBellSlash,
  faBadgeDollar,
  faPaperclip,
  faFileImage,
  faArrowRight,
  faCircleCheck,
  faCircleExclamationCheck,
  faCircle,
  faCircleDashed,
  faReply,
  faArrowLeft,
  faPenToSquare,
  faImage,
  faSquare,
  faArrowRotateRight,
  faLock,
  faDownload,
  faArrowUpRight,
  faGear,
  faMessagePen,
  faMagnifyingGlassPlus,
  faCopy,
  faMapPin,
  faCloudArrowDown,
  faUndo,
} from '@fortawesome/pro-light-svg-icons'

import {
  faPlus,
  faCheck,
  faXmark,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faSortUp,
  faSortDown,
  faCircleExclamation,
} from '@fortawesome/pro-solid-svg-icons'

// this simply abstracts FontAwesome from the rest of our app -- ifever we should switch.
function RXRIcon(props) {
  const style = {
    fontSize: props.size || RXRIcon.SIZE_DEFAULT,
    ...props.style,
  }
  return <FontAwesomeIcon style={style} icon={ICON_KEY_TO_COMP[props.icon]} className={props.className} color={props.color} />
}

RXRIcon.AMENITIES = 'fa-dumbbell'
RXRIcon.ANNOUNCEMENTS = 'fa-megaphone'
RXRIcon.ARROW_DOWN = 'fa-chevron-down'
RXRIcon.ARROW_LEFT = 'fa-chevron-left'
RXRIcon.ARROW_RIGHT = 'fa-chevron-right'
RXRIcon.ARROW_UP = 'fa-chevron-up'
RXRIcon.ARROW_LONG_UP = 'fa-arrow-up'
RXRIcon.ARROW_LONG_DOWN = 'fa-arrow-down'
RXRIcon.ARROW_LONG_RIGHT = 'fa-arrow-right'
RXRIcon.ARROW_LONG_LEFT = 'fa-arrow-left'
RXRIcon.ARROW_TURN_DOWN_RIGHT = 'fa-arrow-turn-down-right'
RXRIcon.ARROW_UP_RIGHT = 'fa-arrow-up-right'
RXRIcon.ATTACHMENT = 'fa-paperclip'
RXRIcon.BIRTHDAY = 'fa-cake-candles'
RXRIcon.BUILDING_ANNIVERSARY = 'building-anniversary'
RXRIcon.BUILDINGS = 'fa-building'
RXRIcon.CALENDAR = 'fa-calendar-days'
RXRIcon.CELEBRATION = 'fa-champagne-glasses'
RXRIcon.CHECK = 'fa-check'
RXRIcon.CLEANING = 'fa-sparkles'
RXRIcon.CLOSE = 'fa-x-mark'
RXRIcon.CLOCK = 'fa-clock'
RXRIcon.COMMAND_CENTER = 'fa-chart-area'
RXRIcon.COPY = 'fa-copy'
RXRIcon.DELIVERIES = 'fa-box-open'
RXRIcon.DISCOUNTS = 'fa-badge-dollar'
RXRIcon.DOG_WALKING = 'fa-dog-leashed'
RXRIcon.DOLLAR_SIGN = 'fa-dollar-sign'
RXRIcon.ELLIPSIS = 'fa-ellipsis'
RXRIcon.ELLIPSIS_VERTICAL = 'fa-ellipsis-vertical'
RXRIcon.ERROR = 'fa-circle-exclamation'
RXRIcon.EDIT = 'fa-pen-to-square'
RXRIcon.EDIT_PROFILE = 'fa-user-pen'
RXRIcon.EVENTS = 'fa-calendar-star'
RXRIcon.FILE_IMAGE = 'fa-file-image'
RXRIcon.GUEST_AUTH = 'fa-key'
RXRIcon.PICTURE = 'fa-image'
RXRIcon.HAMBURGER = 'fa-bars'
RXRIcon.HOME = 'fa-house-user'
RXRIcon.INFO = 'fa-circle-info'
RXRIcon.INFO_I_ONLY = 'fa-info'
RXRIcon.LOG_OUT = 'fa-arrow-right-from-bracket'
RXRIcon.MAINTENANCE = 'fa-screwdriver-wrench'
RXRIcon.MASSAGE = 'fa-spa'
RXRIcon.MESSAGES = 'fa-comment-dots'
RXRIcon.MESSAGES_OFF = 'fa-comment-slash'
RXRIcon.NOT_ALLOWED = 'fa-circle-quarters'
RXRIcon.NUDGE = 'fa-bell-quarters'
RXRIcon.PEN = 'fa-pen-clip'
RXRIcon.PET_BIRTHDAY = 'fa-paw'
RXRIcon.PLUS = 'fa-plus'
RXRIcon.PRINT = 'fa-cloud-arrow-down'
RXRIcon.PROFILE = 'fa-circle-user'
RXRIcon.PERSONAL_TRAINING = 'fa-person-running'
RXRIcon.REFRESH = 'fa-rotate-right'
RXRIcon.RXO_ASSISTANT = 'fa-hat-wizard'
RXRIcon.SEARCH = 'fa-magnifying-glass'
RXRIcon.SERVICES = 'fa-bell-concierge'
RXRIcon.SEND = 'fa-paper-plane-top'
RXRIcon.SETTINGS = 'fa-gear'
RXRIcon.SLIDERS = 'fa-sliders'
RXRIcon.SMILE_BIG = 'fa-face-smile-beam'
RXRIcon.SORT_NONE = 'fa-sort'
RXRIcon.SORT_ASCENDING = 'fa-sort-up'
RXRIcon.SORT_DESCENDING = 'fa-sort-down'
RXRIcon.SOUNDS_OFF = 'fa-bell-slash'
RXRIcon.SOUNDS_ON = 'fa-bell'
RXRIcon.SQUARE = 'fa-square'
RXRIcon.STAFF_NOTES = 'fa-notebook'
RXRIcon.STAR = 'fa-star'
RXRIcon.TIMELINE = 'fa-square-list'
RXRIcon.TRASH = 'fa-trash-can'
RXRIcon.UNDO = 'fa-undo'
RXRIcon.UNREAD_MESSAGES = 'fa-envelope-open-text'
RXRIcon.ONBOARDING = 'fa-circle-check'
RXRIcon.REVIEW_NEEDED = 'fa-circle-exclamation-check'
RXRIcon.TODO = 'fa-circle'
RXRIcon.OPTIONAL = 'fa-circle-dashed'
RXRIcon.CHANGES_REQUESTED = 'fa-reply'
RXRIcon.LOCK = 'fa-lock'
RXRIcon.DOWNLOAD = 'fa-download'
RXRIcon.FEEDBACK = 'fa-message-pen'
RXRIcon.VIEW_ATTACHED_FILE = 'fa-magnifying-glass-plus'
RXRIcon.MAP_PIN = 'fa-map-pin'

const ICON_KEY_TO_COMP = {
  [RXRIcon.AMENITIES]: faDumbbell,
  [RXRIcon.ANNOUNCEMENTS]: faMegaphone,
  [RXRIcon.ARROW_DOWN]: faChevronDown,
  [RXRIcon.ARROW_LEFT]: faChevronLeft,
  [RXRIcon.ARROW_RIGHT]: faChevronRight,
  [RXRIcon.ARROW_UP]: faChevronUp,
  [RXRIcon.ARROW_LONG_DOWN]: faArrowDown,
  [RXRIcon.ARROW_LONG_UP]: faArrowUp,
  [RXRIcon.ARROW_LONG_RIGHT]: faArrowRight,
  [RXRIcon.ARROW_TURN_DOWN_RIGHT]: faArrowTurnDownRight,
  [RXRIcon.ATTACHMENT]: faPaperclip,
  [RXRIcon.BIRTHDAY]: faCakeCandles,
  [RXRIcon.BUILDING_ANNIVERSARY]: faCalendarStar,
  [RXRIcon.BUILDINGS]: faBuilding,
  [RXRIcon.CALENDAR]: faCalendarDays,
  [RXRIcon.CELEBRATION]: faChampagneGlasses,
  [RXRIcon.CHECK]: faCheck,
  [RXRIcon.CLEANING]: faSparkles,
  [RXRIcon.CLOSE]: faXmark,
  [RXRIcon.CLOCK]: faClock,
  [RXRIcon.COMMAND_CENTER]: faChartArea,
  [RXRIcon.COPY]: faCopy,
  [RXRIcon.DELIVERIES]: faBoxOpen,
  [RXRIcon.DISCOUNTS]: faBadgeDollar,
  [RXRIcon.DOG_WALKING]: faDogLeashed,
  [RXRIcon.DOLLAR_SIGN]: faDollarSign,
  [RXRIcon.EDIT]: faPenToSquare,
  [RXRIcon.EDIT_PROFILE]: faUserPen,
  [RXRIcon.ELLIPSIS]: faEllipsis,
  [RXRIcon.ELLIPSIS_VERTICAL]: faEllipsisVertical,
  [RXRIcon.ERROR]: faCircleExclamation,
  [RXRIcon.EVENTS]: faCalendarStar,
  [RXRIcon.FILE_IMAGE]: faFileImage,
  [RXRIcon.GUEST_AUTH]: faKey,
  [RXRIcon.PICTURE]: faImage,
  [RXRIcon.HAMBURGER]: faBars,
  [RXRIcon.HOME]: faHouseUser,
  [RXRIcon.INFO]: faCircleInfo,
  [RXRIcon.INFO_I_ONLY]: faInfo,
  [RXRIcon.LOG_OUT]: faArrowRightFromBracket,
  [RXRIcon.MAINTENANCE]: faScrewdriverWrench,
  [RXRIcon.MAP_PIN]: faMapPin,
  [RXRIcon.MASSAGE]: faSpa,
  [RXRIcon.MESSAGES]: faCommentDots,
  [RXRIcon.MESSAGES_OFF]: faCommentSlash,
  [RXRIcon.NOT_ALLOWED]: faCircleQuarters,
  [RXRIcon.NUDGE]: faBell,
  [RXRIcon.SOUNDS_ON]: faBell,
  [RXRIcon.SOUNDS_OFF]: faBellSlash,
  [RXRIcon.PEN]: faPenClip,
  [RXRIcon.PET_BIRTHDAY]: faPaw,
  [RXRIcon.PRINT]: faCloudArrowDown,
  [RXRIcon.PROFILE]: faCircleUser,
  [RXRIcon.PLUS]: faPlus,
  [RXRIcon.PERSONAL_TRAINING]: faPersonRunning,
  [RXRIcon.RXO_ASSISTANT]: faHatWizard,
  [RXRIcon.REFRESH]: faArrowRotateRight,
  [RXRIcon.SEARCH]: faMagnifyingGlass,
  [RXRIcon.SERVICES]: faBellConcierge,
  [RXRIcon.SEND]: faPaperPlaneTop,
  [RXRIcon.SETTINGS]: faGear,
  [RXRIcon.SLIDERS]: faSliders,
  [RXRIcon.SMILE_BIG]: faSmileBeam,
  [RXRIcon.SORT_NONE]: faSort,
  [RXRIcon.SORT_ASCENDING]: faSortUp,
  [RXRIcon.SORT_DESCENDING]: faSortDown,
  [RXRIcon.STAFF_NOTES]: faNotebook,
  [RXRIcon.STAR]: faStar,
  [RXRIcon.SQUARE]: faSquare,
  [RXRIcon.TRASH]: faTrashCan,
  [RXRIcon.TIMELINE]: faSquareList,
  [RXRIcon.UNDO]: faUndo,
  [RXRIcon.UNREAD_MESSAGES]: faEnvelopeOpenText,
  [RXRIcon.ONBOARDING]: faCircleCheck,
  [RXRIcon.REVIEW_NEEDED]: faCircleExclamationCheck,
  [RXRIcon.TODO]: faCircle,
  [RXRIcon.OPTIONAL]: faCircleDashed,
  [RXRIcon.CHANGES_REQUESTED]: faReply,
  [RXRIcon.ARROW_LONG_LEFT]: faArrowLeft,
  [RXRIcon.LOCK]: faLock,
  [RXRIcon.DOWNLOAD]: faDownload,
  [RXRIcon.ARROW_UP_RIGHT]: faArrowUpRight,
  [RXRIcon.FEEDBACK]: faMessagePen,
  [RXRIcon.VIEW_ATTACHED_FILE]: faMagnifyingGlassPlus,
}

RXRIcon.SIZE_SMALL = Spacing.iconSizeSmall
RXRIcon.SIZE_DEFAULT = Spacing.iconSizeMedium
RXRIcon.SIZE_LARGE = Spacing.iconSizeLarge
RXRIcon.SIZE_EXTRA_LARGE = Spacing.iconSizeExtraLarge

RXRIcon.BUTTON_SIZE_SMALL = Spacing.iconButtonSizeSmall
RXRIcon.BUTTON_SIZE_DEFAULT = Spacing.iconButtonSizeMedium
RXRIcon.BUTTON_SIZE_LARGE = Spacing.iconButtonSizeLarge
RXRIcon.BUTTON_SIZE_EXTRA_LARGE = Spacing.iconButtonSizeExtraLarge

RXRIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.any,
  className: PropTypes.any,
}

export default RXRIcon
