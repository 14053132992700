import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import {StylesProvider, createGenerateClassName} from '@mui/styles'
import {ThemeProvider} from '@mui/material/styles'
import theme from './theme'
import {createStore} from 'redux'
import rootReducer from './reducers'
import {Provider} from 'react-redux'
import {HashRouter} from 'react-router-dom'
import {StyledEngineProvider} from '@mui/material'
import * as Sentry from '@sentry/react'
import {ExtraErrorData} from '@sentry/integrations'
import {Integrations} from '@sentry/tracing'
import pjson from '../package.json'
import config from '../../src/amplifyconfiguration.json'
import {ErrorBoundary} from 'react-error-boundary'
import ErrorBoundaryFallback from './components/ErrorBoundaryFallback'

const ENV = config.aws_content_delivery_bucket.split('-').slice(-1)[0]
Sentry.init({
  dsn: 'https://3b7417f16090e3631a5500fa29923cf5@o407746.ingest.us.sentry.io/4508840495022080',
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  integrations: [new ExtraErrorData({depth: 10}), new Integrations.BrowserTracing()],
  normalizeDepth: 11, // @see https://github.com/getsentry/sentry-javascript/issues/1964
  environment: ENV,
  release: 'vendor-dashboard@' + pjson.version,
  beforeSend(event, hint) {
    const error = hint.originalException
    if (error) {
      let errorMessage = error.message || ''

      // Convert error message to lower case for ease of comparison
      errorMessage = errorMessage.toLowerCase()

      if (
        errorMessage.includes('network error') ||
        errorMessage.includes('not authenticated') ||
        errorMessage.includes('no current user') ||
        errorMessage.includes('request failed with status code 401') ||
        errorMessage.includes('no time remaining') ||
        errorMessage.includes(`unprocessable entity:422, {"message":"selected time slot no longer available"}`) ||
        errorMessage.includes('non-error exception captured with keys: code, message, name')
      ) {
        return null // Drop the event
      }
    }
    return event
  },
})

const store = createStore(rootReducer)

// Because we import files from the dashboard (like RXRButton), the duplicate makeStyles commands
// were causing classname collisions (e.g., `jss2` being decalred twice). To avoide this, we
// use this prodClassGenerator that appends all classnames generated in onboarding components with an `rxo-onb-` prefix.
const prodClassGenerator = createGenerateClassName({
  productionPrefix: 'rxo-onb-',
})

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <HashRouter basename="/">
      <Provider store={store}>
        <StylesProvider generateClassName={prodClassGenerator}>
          <ThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
              <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onReset={() => window.location.reload()}>
                <App />
              </ErrorBoundary>
            </StyledEngineProvider>
          </ThemeProvider>
        </StylesProvider>
      </Provider>
    </HashRouter>
  </React.StrictMode>,
)
