import AbstractCollection from './AbstractCollection'
import utilities from '../utilities'

class Unit extends AbstractCollection {

    /**
   * @param { {id: ID} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async getUnit(input, options) {
    return this._get(
       'getUnit',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {id: ID} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async getUnit(input, options) {
    return new this().getUnit(input, options)
  }

  /**
   * @param { {filter?: ModelUnitFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  async listUnits(input, options) {
    return this._list(
       'listUnits',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {filter?: ModelUnitFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  static async listUnits(input, options) {
    return new this().listUnits(input, options)
  }

  /**
   * @param { {filter?: ModelUnitFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  async iterateUnits(input, options) {
    return this._iterate(
       'listUnits',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {filter?: ModelUnitFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  static async iterateUnits(input, options) {
    return new this().iterateUnits(input, options)
  }

  /**
   * @param { {unitBuildingId?: ID, number?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelUnitFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  async unitsByBuildingAndNumber(input, options) {
    return this._list(
       'unitsByBuildingAndNumber',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {unitBuildingId?: ID, number?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelUnitFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  static async unitsByBuildingAndNumber(input, options) {
    return new this().unitsByBuildingAndNumber(input, options)
  }

  /**
   * @param { {unitBuildingId?: ID, number?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelUnitFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  async iterateunitsByBuildingAndNumber(input, options) {
    return this._iterate(
       'unitsByBuildingAndNumber',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {unitBuildingId?: ID, number?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelUnitFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  static async iterateunitsByBuildingAndNumber(input, options) {
    return new this().iterateunitsByBuildingAndNumber(input, options)
  }

  /**
   * @param { {input: CreateUnitInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async createUnit(input, options) {
    return this._mutate(
       'createUnit',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: CreateUnitInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async createUnit(input, options) {
    return new this().createUnit(input, options)
  }

  /**
   * @param { {input: UpdateUnitInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async updateUnit(input, options) {
    return this._mutate(
       'updateUnit',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: UpdateUnitInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async updateUnit(input, options) {
    return new this().updateUnit(input, options)
  }

  /**
   * @param { {input: DeleteUnitInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async deleteUnit(input, options) {
    return this._mutate(
       'deleteUnit',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: DeleteUnitInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async deleteUnit(input, options) {
    return new this().deleteUnit(input, options)
  }


  // STATIC --------------------------------------------------------------
  static modelName = "Unit"

  /**
   * @param {*} obj
   * @returns {*}
   */
  static afterFind(obj) {
    // if we couldn't find any data, just return early -- nothing to format
    if (!obj) {
      return obj
    }

    Object.entries(Unit._complexFields).forEach(([field, fieldType]) => {
      // if this connected field is included in our response object
      if (Object.hasOwnProperty.call(obj, field)) {
        // we determine the correct model this field is connected to
        const modelName = utilities.getModelFromFieldType(fieldType)

        // load that models collection
        const associatedCollection = Unit.modelNameToCollectionMap[modelName]

        if (associatedCollection) {
          // and invoke that collection's afterFind method.
          // this allows any special query hook behavior for the associated collections to be applied to this payload as well
          obj[field] = Array.isArray(obj[field]) ? obj[field].map(v => associatedCollection.afterFind(v)) : associatedCollection.afterFind(obj[field])
        }
      }
    })


    // lastly, we apply our own custom query hooks to this payload if one is specified
    if (typeof Unit.custom_afterFind === 'function') {
      return Unit.custom_afterFind(obj)
    }

    return obj
  }

  /**
   * @override
   * @param {string?} fragmentName
   * @returns { Unit }
   */
  static as(fragmentName) {
    return new this(fragmentName)
  }
}

// this meta prop is used to call query hooks on associated models
Unit._complexFields = {"building":"Building!","occupancies":"[Occupancy]","guests":"[Guest]"}
Unit._jsonFields = []

Unit.FragmentDefault = `
fragment UnitFragmentDefault on Unit {
  id
  number
  unitBuildingId
  sqft
  bedrooms
  bathrooms
  createdAt
  updatedAt
}

`


export default Unit
