import AbstractCollection from './AbstractCollection'
import utilities from '../utilities'

class Resident extends AbstractCollection {

    /**
   * @param { {id: ID} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async getResident(input, options) {
    return this._get(
       'getResident',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {id: ID} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async getResident(input, options) {
    return new this().getResident(input, options)
  }

  /**
   * @param { {filter?: ModelResidentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  async listResidents(input, options) {
    return this._list(
       'listResidents',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {filter?: ModelResidentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  static async listResidents(input, options) {
    return new this().listResidents(input, options)
  }

  /**
   * @param { {filter?: ModelResidentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  async iterateResidents(input, options) {
    return this._iterate(
       'listResidents',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {filter?: ModelResidentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  static async iterateResidents(input, options) {
    return new this().iterateResidents(input, options)
  }

  /**
   * @param { {secondaryReferenceId?: String, referenceId?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelResidentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  async listResidentsByReferenceId(input, options) {
    return this._list(
       'listResidentsByReferenceId',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {secondaryReferenceId?: String, referenceId?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelResidentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  static async listResidentsByReferenceId(input, options) {
    return new this().listResidentsByReferenceId(input, options)
  }

  /**
   * @param { {secondaryReferenceId?: String, referenceId?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelResidentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  async iterateResidentsByReferenceId(input, options) {
    return this._iterate(
       'listResidentsByReferenceId',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {secondaryReferenceId?: String, referenceId?: ModelStringKeyConditionInput, sortDirection?: ModelSortDirection, filter?: ModelResidentFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  static async iterateResidentsByReferenceId(input, options) {
    return new this().iterateResidentsByReferenceId(input, options)
  }

  /**
   * @param { {residentId: ID, subject?: String, body?: String, badge?: Int, appJSON?: String} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async sendNotification(input, options) {
    return this._mutate(
       'sendNotification',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {residentId: ID, subject?: String, body?: String, badge?: Int, appJSON?: String} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async sendNotification(input, options) {
    return new this().sendNotification(input, options)
  }

  /**
   * @param { {input: CreateResidentUserInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async createResidentUser(input, options) {
    return this._mutate(
       'createResidentUser',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: CreateResidentUserInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async createResidentUser(input, options) {
    return new this().createResidentUser(input, options)
  }

  /**
   * @param { {input: CreateResidentInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async createResident(input, options) {
    return this._mutate(
       'createResident',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: CreateResidentInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async createResident(input, options) {
    return new this().createResident(input, options)
  }

  /**
   * @param { {input: UpdateResidentInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async updateResident(input, options) {
    return this._mutate(
       'updateResident',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: UpdateResidentInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async updateResident(input, options) {
    return new this().updateResident(input, options)
  }

  /**
   * @param { {input: DeleteResidentInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async deleteResident(input, options) {
    return this._mutate(
       'deleteResident',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: DeleteResidentInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async deleteResident(input, options) {
    return new this().deleteResident(input, options)
  }


  // STATIC --------------------------------------------------------------
  static modelName = "Resident"

  /**
   * @param {*} obj
   * @returns {*}
   */
  static afterFind(obj) {
    // if we couldn't find any data, just return early -- nothing to format
    if (!obj) {
      return obj
    }

    Object.entries(Resident._complexFields).forEach(([field, fieldType]) => {
      // if this connected field is included in our response object
      if (Object.hasOwnProperty.call(obj, field)) {
        // we determine the correct model this field is connected to
        const modelName = utilities.getModelFromFieldType(fieldType)

        // load that models collection
        const associatedCollection = Resident.modelNameToCollectionMap[modelName]

        if (associatedCollection) {
          // and invoke that collection's afterFind method.
          // this allows any special query hook behavior for the associated collections to be applied to this payload as well
          obj[field] = Array.isArray(obj[field]) ? obj[field].map(v => associatedCollection.afterFind(v)) : associatedCollection.afterFind(obj[field])
        }
      }
    })

    // automatically JSON Parse all AWSJSON fields
    Resident._jsonFields.forEach(field => {
      if (Object.hasOwnProperty.call(obj, field) && typeof obj[field] === 'string') {
        obj[field] = JSON.parse(obj[field])
      }
    })

    // lastly, we apply our own custom query hooks to this payload if one is specified
    if (typeof Resident.custom_afterFind === 'function') {
      return Resident.custom_afterFind(obj)
    }

    return obj
  }

  /**
   * @override
   * @param {string?} fragmentName
   * @returns { Resident }
   */
  static as(fragmentName) {
    return new this(fragmentName)
  }
}

// this meta prop is used to call query hooks on associated models
Resident._complexFields = {"occupancy":"Occupancy","conversation":"Conversation","profileImage":"S3Object","payments":"[Payment]","paymentMethods":"[PaymentMethod]","serviceRequests":"[ServiceRequest!]","notificationSettings":"[NotificationSetting]","autoPay":"[AutoPay]","guests":"[Guest]","vendorServiceCredits":"[VendorServiceCredit]","announcements":"[ResidentAnnouncement]"}
Resident._jsonFields = ["onboardingMeta","smartRentMeta",]

Resident.FragmentDefault = `
fragment ResidentFragmentDefault on Resident {
  id
  createdAt
  updatedAt
  phoneNumber
  email
  displayName
  residentOccupancyId
  referenceId
  secondaryReferenceId
  propertyCodeOverride
  prospectCode
  userProfile
  pushAddress
  moveInDate
  moveOutDate
  statusOverride
  isTest
  dateClaimed
  nudgeCheckedAt
  isDeleted
  onboardingMeta
  lastLoggedInAt
  smartRentMeta
}

`


export default Resident
