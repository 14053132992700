import AbstractCollection from './AbstractCollection'
import utilities from '../utilities'

class Staff extends AbstractCollection {

    /**
   * @param { {buildingId: ID} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async listStaffAtBuilding(input, options) {
    return this._get(
       'listStaffAtBuilding',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {buildingId: ID} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async listStaffAtBuilding(input, options) {
    return new this().listStaffAtBuilding(input, options)
  }

  /**
   * @param { {id: ID} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async getStaff(input, options) {
    return this._get(
       'getStaff',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {id: ID} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async getStaff(input, options) {
    return new this().getStaff(input, options)
  }

  /**
   * @param { {filter?: ModelStaffFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  async listStaffs(input, options) {
    return this._list(
       'listStaffs',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {filter?: ModelStaffFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<Array<*>>}
   */
  static async listStaffs(input, options) {
    return new this().listStaffs(input, options)
  }

  /**
   * @param { {filter?: ModelStaffFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  async iterateStaffs(input, options) {
    return this._iterate(
       'listStaffs',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {filter?: ModelStaffFilterInput, limit?: Int, nextToken?: String?} } input
   * @param {QueryOptions?} options
   * @returns {Promise<GQLQueryIterator>}
   */
  static async iterateStaffs(input, options) {
    return new this().iterateStaffs(input, options)
  }

  /**
   * @param { {input: CreateGroupUserInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async createGroupUser(input, options) {
    return this._mutate(
       'createGroupUser',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: CreateGroupUserInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async createGroupUser(input, options) {
    return new this().createGroupUser(input, options)
  }

  /**
   * @param { {input: UpdateGroupUserInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async updateGroupUser(input, options) {
    return this._mutate(
       'updateGroupUser',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: UpdateGroupUserInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async updateGroupUser(input, options) {
    return new this().updateGroupUser(input, options)
  }

  /**
   * @param { {input: DisableGroupUserInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async disableGroupUser(input, options) {
    return this._mutate(
       'disableGroupUser',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: DisableGroupUserInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async disableGroupUser(input, options) {
    return new this().disableGroupUser(input, options)
  }

  /**
   * @param { {input: ResetGroupUserPasswordInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async resetGroupUserPassword(input, options) {
    return this._mutate(
       'resetGroupUserPassword',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: ResetGroupUserPasswordInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async resetGroupUserPassword(input, options) {
    return new this().resetGroupUserPassword(input, options)
  }

  /**
   * @param { {input: CreateStaffInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async createStaff(input, options) {
    return this._mutate(
       'createStaff',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: CreateStaffInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async createStaff(input, options) {
    return new this().createStaff(input, options)
  }

  /**
   * @param { {input: UpdateStaffInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async updateStaff(input, options) {
    return this._mutate(
       'updateStaff',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: UpdateStaffInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async updateStaff(input, options) {
    return new this().updateStaff(input, options)
  }

  /**
   * @param { {input: DeleteStaffInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  async deleteStaff(input, options) {
    return this._mutate(
       'deleteStaff',
       input,
       this.fragmentName,
       options,
    )
  }

  /**
   * @param { {input: DeleteStaffInput} } input
   * @param {QueryOptions?} options
   * @returns {Promise<*>}
   */
  static async deleteStaff(input, options) {
    return new this().deleteStaff(input, options)
  }


  // STATIC --------------------------------------------------------------
  static modelName = "Staff"

  /**
   * @param {*} obj
   * @returns {*}
   */
  static afterFind(obj) {
    // if we couldn't find any data, just return early -- nothing to format
    if (!obj) {
      return obj
    }

    Object.entries(Staff._complexFields).forEach(([field, fieldType]) => {
      // if this connected field is included in our response object
      if (Object.hasOwnProperty.call(obj, field)) {
        // we determine the correct model this field is connected to
        const modelName = utilities.getModelFromFieldType(fieldType)

        // load that models collection
        const associatedCollection = Staff.modelNameToCollectionMap[modelName]

        if (associatedCollection) {
          // and invoke that collection's afterFind method.
          // this allows any special query hook behavior for the associated collections to be applied to this payload as well
          obj[field] = Array.isArray(obj[field]) ? obj[field].map(v => associatedCollection.afterFind(v)) : associatedCollection.afterFind(obj[field])
        }
      }
    })

    // automatically JSON Parse all AWSJSON fields
    Staff._jsonFields.forEach(field => {
      if (Object.hasOwnProperty.call(obj, field) && typeof obj[field] === 'string') {
        obj[field] = JSON.parse(obj[field])
      }
    })

    // lastly, we apply our own custom query hooks to this payload if one is specified
    if (typeof Staff.custom_afterFind === 'function') {
      return Staff.custom_afterFind(obj)
    }

    return obj
  }

  /**
   * @override
   * @param {string?} fragmentName
   * @returns { Staff }
   */
  static as(fragmentName) {
    return new this(fragmentName)
  }
}

// this meta prop is used to call query hooks on associated models
Staff._complexFields = {"buildings":"[BuildingStaff]","messages":"[Message]","notificationSettings":"[NotificationSetting]","profileImage":"S3Object"}
Staff._jsonFields = ["passwordResetMeta",]

Staff.FragmentDefault = `
fragment StaffFragmentDefault on Staff {
  id
  isAdmin
  phoneNumber
  userName
  passwordResetMeta
  displayName
  permissionGroup
  dateClaimed
  lastLoggedInAt
  createdAt
  updatedAt
}

`


export default Staff
